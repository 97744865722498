export const defaultPaymentTypes = [
  {
    title: 'Оплата наличными в филиале',
    key: 'cash',
  },
  {
    title: 'Наложенный платеж',
    subTitle: 'Оплата после получения заказа в пункте выдачи',
    key: 'cod',
  },
  {
    title: 'Картой онлайн',
    subTitle: 'Оплата банковской картой на сайте',
    key: 'card',
  },
  // Есть еще такие методы
  // {
  //     title: 'Рассрочка',
  //     subTitle: 'Без % до 3 месяцев',
  //     key: 'instalments',
  // },
  // {
  //     title: 'СБП',
  //     subTitle: 'Оплата по QR-коду',
  //     key: 'sbp',
  // },
];

export const manelyakPaymentTypes = [
  {
    title: 'Оплата наличными в филиале',
    key: 'cash',
  },
];
